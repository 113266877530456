<script>
import TemplateHeaderTableNew from '@/components/TemplateHeaderTableList'
import { downloadAction, getAction, getParams } from '@/command/netTool'
import moment from 'moment'
import Big from 'big.js'
export default {
  name: 'dsplatform',
  data() {
    return {
      records: [],
      totalData: null,
      current: 1,
      total: 1,
      // listUrl: '/reportForm/getDsReportList',
      params: { current: 1, pageSize: 10 },
      paramsValue: { host: '/api' },
      shTypeList: [],
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getTotal(params) {
      return new Promise((resolve) => {
        // if (this.totalData) {
        //   resolve(this.totalData)
        // } else {
        this.params = {
          ...this.params,
          ...params,
        }
        getAction('/reportForm/getDsReportOrderTotal', params).then((e) => {
          this.totalData = e.data
          resolve(e.data)
        })
        // }
      })
    },
    getSum(list) {
      let orderCount = 0
      let totalAmount = 0
      let productQuantity = 0
      let productQuantityCompleted = 0
      let totalAmountCompleted = 0
      let refundCount = 0
      let salesJsAmount = 0
      let refundAmount = 0
      let feeAmount = 0
      list.forEach((e) => {
        orderCount = parseFloat(new Big(e.orderCount || 0).plus(orderCount))
        totalAmount = parseFloat(new Big(e.totalAmount || 0).plus(totalAmount))
        productQuantity = parseFloat(new Big(e.productQuantity || 0).plus(productQuantity))
        productQuantityCompleted = parseFloat(new Big(e.productQuantityCompleted || 0).plus(productQuantityCompleted))
        totalAmountCompleted = parseFloat(new Big(e.totalAmountCompleted || 0).plus(totalAmountCompleted))
        refundCount = parseFloat(new Big(e.refundCount || 0).plus(refundCount))
        salesJsAmount = parseFloat(new Big(e.salesJsAmount || 0).plus(salesJsAmount))
        refundAmount = parseFloat(new Big(e.refundAmount || 0).plus(refundAmount))
        feeAmount = parseFloat(new Big(e.feeAmount || 0).plus(feeAmount))
      })
      return {
        shType: '合计',
        orderCount,
        totalAmount,
        productQuantity,
        productQuantityCompleted,
        totalAmountCompleted,
        refundCount,
        salesJsAmount,
        refundAmount,
        feeAmount,
        type: '1',
      }
    },
    getList(params = this.params) {
      this.getTotal(params).then((total) => {
        getAction('/reportForm/getDsReportList', params).then((e) => {
          this.records = e.data
          //   this.params = { ...params }
          //   this.params['current'] = e.data['current']
          //   this.params['pageSize'] = e.data['pageSize']
          //   this.current = e.data['current']
          //   this.total = e.data['total']
          if (this.records.length !== 0) {
            this.records.push(this.getSum(e.data))
            this.records.push({
              ...total,
              orderCount: total.orderCount,
              totalAmount: total.totalAmount,
              feeAmount: total.feeAmount,
              shType: '总计',
              type: '1',
            })
          }
        })
      })
    },
    getHeader() {
      return [
        {
          name: '日期',
          type: 'rangePicker',
          keys: ['dateGe', 'dateLe'],
          props: {
            allowClear: true,
          },
        },
        {
          type: 'select',
          name: '产品类型',
          key: 'shType',
          typeData: [
            {
              name: '农家特产',
              value: '1',
            },
            {
              name: '农家乐',
              value: '2',
            },
            {
              name: '景点门票',
              value: '1201',
            },
            {
              name: '渔家乐',
              value: '1202',
            },
            {
              name: '酒店民宿',
              value: '13',
            },
          ],
        },
      ]
    },
    getColumns() {
      return [
        {
          title: '商户类型',
          dataIndex: 'shType',
          customRender: (text) => {
            // 1文创; 2餐饮; 1201 景点门票; 1202 渔家乐; 13酒店民宿;
            if (text == '合计' || text == '总计') {
              return text
            }
            let type = ''
            if (text == '1') {
              type = '农家特产'
            } else if (text == 2) {
              type = '农家乐'
            } else if (text == 1201) {
              type = '景点门票'
            } else if (text == 1202) {
              type = '渔家乐'
            } else if (text == 13) {
              type = '酒店民宿'
            }
            return <span>{type}</span>
          },
        },

        {
          title: '订单数',
          dataIndex: 'orderCount',
          sorter: (a, b, sortOrder) => {
            if (a.shType == '合计' || a.shType == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.orderCount - b.orderCount
            }
          },
        },
        {
          title: '张数',
          dataIndex: 'productQuantity',
        },
        {
          title: '订单金额',
          dataIndex: 'totalAmount',
          sorter: (a, b, sortOrder) => {
            if (a.shType == '合计' || a.shType == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmount - b.totalAmount
            }
          },
        },
        {
          title: '退款数量',
          dataIndex: 'refundCount',
          sorter: (a, b, sortOrder) => {
            if (a.shType == '合计' || a.shType == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.refundCount - b.refundCount
            }
          },
        },
        {
          title: '退款金额',
          dataIndex: 'refundAmount',
          sorter: (a, b, sortOrder) => {
            if (a.shType == '合计' || a.shType == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.refundAmount - b.refundAmount
            }
          },
        },
        {
          title: '核销数量',
          dataIndex: 'productQuantityCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.shType == '合计' || a.shType == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.productQuantityCompleted - b.productQuantityCompleted
            }
          },
        },
        {
          title: '核销金额',
          dataIndex: 'totalAmountCompleted',
          sorter: (a, b, sortOrder) => {
            if (a.shType == '合计' || a.shType == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.totalAmountCompleted - b.totalAmountCompleted
            }
          },
          customRender: (text) => {
            return <span>{parseFloat(text).toFixed(2)}</span>
          },
        },
        {
          title: '手续费',
          dataIndex: 'feeAmount',
          sorter: (a, b, sortOrder) => {
            if (a.createTime == '合计' || a.createTime == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.feeAmount - b.feeAmount
            }
          },
        },
        {
          title: '结算金额',
          dataIndex: 'salesJsAmount',
          sorter: (a, b, sortOrder) => {
            if (a.shType == '合计' || a.shType == '总计') {
              if (sortOrder == 'ascend') {
                return 9999999999999
              } else if (sortOrder == 'descend') {
                return -10000
              } else {
                return 99999999999999
              }
            } else {
              return a.salesJsAmount - b.salesJsAmount
            }
          },
        },
      ]
    },
    onChange(data, filter) {
      this.getList(data)
    },
    getRightButton() {
      return [
        {
          name: '导出Excel',
          type: 'normal2',
          onClick: (data) => {
            const {
              shType,
              dateGe,
              dateLe,
              orderCountGe,
              orderCountLe,
              productQuantityCompletedGe,
              productQuantityCompletedLe,
              totalAmountCompletedGe,
              totalAmountCompletedLe,
              totalAmountGe,
              totalAmountLe,
            } = this.params

            downloadAction(
              `/api/reportForm/exportDsReport?shType=${shType || ''}&dateGe=${dateGe || ''}&dateLe=${
                dateLe || ''
              }&shTypeList=${this.shTypeList.length ? this.shTypeList : ''}`,
              '岱山平台报表.xlsx'
            )
          },
        },
      ]
    },
    selectRow(data, records) {
      this.shTypeList = records.map((d) => d.shType)
    },
  },
  render() {
    return (
      <TemplateHeaderTableNew
        ref={'table'}
        records={this.records}
        headerData={this.getHeader()}
        tableColumns={this.getColumns()}
        rightButton={this.getRightButton()}
        button={[]}
        filterRightButtonKey={['refresh', 'setting']}
        onChange={this.onChange}
        onSelectRow={this.selectRow}
      />
    )
  },
}
</script>
